<template>
  <div v-if="ready">
   
      <AddEditForm />
   
  </div>
</template>

<script>
export default {
  data() {
    return {
      ready: false,
    };
  },
  components: {
    AddEditForm: () => import("./components/AddEditForm"),
  },
  computed: {
    bankList() {
      return _.map(this.$store.state.unities.bankList, (bank) => {
        return { value: bank.id, text: bank.description };
      });
    },
  },
  async mounted() {
    // await this.$store.dispatch("unities/bank_accounts_add_edit_init");
    this.ready = true;
  },
};
</script>

<style>
</style>